<script>
	import IconButton from '$lib/IconButton.svelte';
	import BellOutlineIcon from 'svelte-material-icons/BellOutline.svelte';
	import ArrowLeftIcon from 'svelte-material-icons/ArrowLeft.svelte';
	import Avatar from '$lib/core/Avatar.svelte';
	import NotificationsIcon from '$lib/core/NotificationsIcon.svelte';

	export let leadIn = '';
	export let title = '';
	export let pageTitle = '';
	export let back = '';
	export let breadcrumbs = [];
	export let showToolbar = true;

	export let profile = {};
</script>

<svelte:head>
	<title>{pageTitle || title} — SCULPT X</title>
</svelte:head>

<div id="header">
	<div style="display: flex; align-items:center; gap: 20px;">
		{#if back}
			<div>
				<a href={back} style="color:inherit;text-decoration:none;">
					<IconButton style="width:38px;height:38px;">
						<ArrowLeftIcon size={22} />
					</IconButton>
				</a>
			</div>
		{/if}

		<div class="flex items-center gap-15">
			{#if profile?.id}
				<a href={`/athlete?id=${profile?.id}`} style="text-decoration:none;">
					<Avatar fName={profile?.fName} lName={profile?.lName} size="38" />
				</a>
			{/if}
			<div>
				<h2>
					{title}
				</h2>

				{#if breadcrumbs?.length}
					<ol class="breadcrumbs">
						{#each breadcrumbs as breadcrumb}
							<li>
								<a href={breadcrumb.href}>{breadcrumb.label}</a>
							</li>
						{/each}
						<li>{title}</li>
					</ol>
				{/if}
			</div>
		</div>
	</div>

	{#if showToolbar && false}
		<div>
			<NotificationsIcon />
		</div>
	{/if}
</div>

<style lang="scss">
	#header {
		height: 85px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-left: 20px;
		padding-right: 20px;

		h2 {
			font-weight: 500;
			font-size: 18px;
			line-height: 18px;
			margin: 0;
		}
	}

	.breadcrumbs {
		display: flex;
		gap: 5px;

		li {
			font-size: 12px;
			color: var(--color-muted);
			display: flex;
			align-items: center;
			gap: 5px;

			&:after {
				content: '';
				width: 5px;
				height: 1px;
				background-color: var(--color-muted);
			}

			&:last-child:after {
				display: none;
			}
		}

		a {
			color: inherit;
			text-decoration: none;

			&:hover {
				color: var(--color-link);
			}
		}
	}
</style>
