<script lang="ts">
	import Button from '$lib/Button.svelte';
	import Section from './Section.svelte';

	export let title = '';
	export let desc = '';
	export let buttonLabel = '';
	export let buttonLink = '';
	export let onStart: any;
</script>

<Section>
	<div class="start-body">
		{#if title}
			<h2>{title}</h2>
		{/if}
		{#if desc}
			<p>
				{desc}
			</p>
		{/if}
		{#if buttonLabel}
			{#if buttonLink}
				<a href={ buttonLink }>
					<Button color="primary">{buttonLabel}</Button>
				</a>
			{:else}
				<Button color="primary" onClick={onStart}>{buttonLabel}</Button>
			{/if}
		{/if}
		<slot />
	</div>
</Section>

<style>
	div {
		padding: 50px 30px;
		margin: 35px 0;
		text-align: center;
	}

	h2 {
		font-size: 26px;
		font-weight: 600;
		color: rgb(7, 20, 55);
		margin-bottom: 20px;
	}

	p {
		font-weight: 500;
		color: var(--color-muted);
		margin-bottom: 20px;
	}
</style>
