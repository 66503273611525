<script>
	import Button from '$lib/Button.svelte';
	import Container from '$lib/Container.svelte';
	import ContentBody from '$lib/ContentBody.svelte';
	import Section from './Section.svelte';
	import Header from './Header.svelte';
	import GettingStartedSection from './GettingStartedSection.svelte';
	import { goto } from '$app/navigation';

	export let title = 'Sorry, the page you have requested does not exist.';
	export let desc = 'Make sure that you have the correct URL.';
	export let buttonLabel = 'Go Back to Home';

	export let headerTitle = 'Page Not Found';
	export let back = '';
</script>

<Header 
	title={headerTitle} 
	{back}
	showToolbar={ false } />

<Container>
	<GettingStartedSection
		{title}
		{desc}
		{buttonLabel}
		onStart={() => {
			goto('/');
		}}
	/>
</Container>
