<script>
	export let title = "";
	export let subtitle = "";
	export let noPadding = false;
</script>

<section { ...$$restProps }>
	{#if title}
		<div class="section-header">
			<div>
				<h2>
					{title}
				</h2>
				{ #if subtitle }
					<div class="text-xs color-muted font-semibold">{ subtitle }</div>
				{ /if }
			</div>
			<slot name="actions" />
		</div>
	{/if}

	<div class="section-body" class:fit={ noPadding }>
		<slot />
	</div>

	{#if $$slots.footer}
		<div class="section-footer">
			<slot name="footer" />
		</div>
	{/if}
</section>

<style lang="scss">
	section {
		position: relative;
		border: 1px solid var(--color-border);
		// box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.03);
		box-shadow: 2px 5px 5px 0px rgba(0, 0, 0, 0.04);
		width: 100%;
		border-radius: 4px;
	}

	.section-body,
	.section-header,
	.section-footer {
		padding: 20px 15px;

		&.fit {
			padding: 0;
		}
	}

	.section-header {
		// border-bottom: 1px solid #F1F1F4;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.section-footer {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-top: 1px solid #f1f1f4;
	}

	h2 {
		font-size: 16px;
		font-weight: 500;
	}
</style>
