<script>
	export let fName = '';
	export let lName = '';

	export let size = 45;
	export let fontSize = 14;
</script>

<div class="avatar" style={ `width:${size}px;height:${size}px;font-size:${fontSize}px` } title={ `${ fName } ${ lName }` }>
	{fName?.[0]?.toUpperCase() || ''}{lName?.[0]?.toUpperCase() || ''}
</div>

<style lang="scss">
	.avatar {
		border-radius: 50%;
		font-size: 16px;
		background-color: #000;
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
	}
</style>