<div class="container" {...$$restProps}>
	<slot />
</div>

<style>
	.container {
		padding: 0 20px;
	}

	@media only screen and (max-width: 1280px) {
		.container {
			padding: 0 40px;
		}
	}
</style>
