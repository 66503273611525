<script>
	export let onClick = () => {};
	export let color = '';
</script>

<button
	on:click={onClick}
	class:primary={color === 'primary'}
	class:primary-light={color === 'primary-light'}
	class:error={color === 'error'}
	{...$$restProps}
>
	<slot />
</button>

<style lang="scss">
	/* Base styles for the button */
	button {
		padding: 10px 20px;
		border-radius: 6px;
		border: none;
		font-weight: 500;
		cursor: pointer;
		transition: background-color 0.2s;
		display: inline-flex;
		align-items: center;
		gap: 10px;
		background-color: #f1f1f4;
		color: #252f4a;

		&:hover {
			color: var(--color-primary);
		}

		&.primary {
			background-color: var(--color-primary);
			color: #fff;

			&:hover {
				background-color: #056ee9; // color-primary-active
			}
		}

		&.error {
			background-color: var(--color-error);
			color: #fff;
		}

		&.primary-light {
			background-color: #e9f3ff;
			color: var(--color-primary);

			&:hover {
				background-color: var(--color-primary);
				color: white;
			}
		}

		&[disabled] {
			background-color: #f1f1f4 !important;
			color: gray !important;
			cursor: not-allowed;
		}
	}
</style>
