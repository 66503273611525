<script>
	export let onClick = () => {}
</script>

<button on:click={ onClick } {...$$restProps}>
	<slot />
</button>

<style lang="scss">
	button {
		padding: 0;
		margin: 0;
		background: none;
		border: 0;
		width: 32px;
		height: 32px;
		border-radius: 50%;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		transition: background 0.3s ease;

		&:hover {
			background-color: lightgray;
		}
	}
</style>
