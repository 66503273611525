import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getMessaging, onMessage } from "firebase/messaging";
import 'firebase/auth';
import { GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword } from "firebase/auth";
import { captureEvent, captureException } from '@sentry/sveltekit';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyBy7DJw_hji1fCGGcPuYv16vhIKh-_JyM4",
  authDomain: "sculpt-x.firebaseapp.com",
  projectId: "sculpt-x",
  storageBucket: "sculpt-x.appspot.com",
  messagingSenderId: "1031259875377",
  appId: "1:1031259875377:web:3a1e3e0e21ef794fefcd10",
  measurementId: "G-T46VW9T6FG"
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const messaging = (typeof window !== "undefined" ?  getMessaging(app) : null)

/*
const messaging = getMessaging();
onMessage(messaging, (payload) => {
  console.log('Message received. ', payload);
  // ...
});
*/


export const analytics = (typeof window !== "undefined" ?  getAnalytics(app) : null)


export async function createUserWithEmail(email, password) {
  if(email && password) {
    try {
      let userCreds = await createUserWithEmailAndPassword(auth, email, password);

      return userCreds?.user;
    } catch(e) {
      captureException(e);
      throw e;
    }
  }
}

export async function signInWithEmail(email, password) {
  if (email && password) {
    try {
      let userCreds = await signInWithEmailAndPassword(auth, email, password)

      return userCreds?.user;
    } catch (e) {
      captureException(e);
      throw e;
    }
  }
}

/// move this away ....
export async function signInWithGoogle() {
  try {
    const provider = new GoogleAuthProvider();

    const userCreds = await signInWithPopup(auth, provider);
    const credential = GoogleAuthProvider.credentialFromResult(userCreds);

    return userCreds.user;

    // Update the userStore with the user data
    // $userStore = user;
  } catch (error) {
    captureException(error);
    throw error;
  }
}